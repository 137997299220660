<template>
  <div class="thumbsUp">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <div class="listItem" v-for="item in list" :key="item.id">
        <div class="topData">
          <div class="type">#涩漫官方</div>
          <div class="date">{{ item.createdAt | dateago }}</div>
        </div>
        <div class="content">{{ item.desc }}</div>
      </div>
    </PullRefresh>
  </div>
</template>

<script>
import {querMyMsg, queryMsgList} from "@/api/mine";
import PullRefresh from "@/components/PullRefresh";
export default {
  components: {
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        type: 4,
      };
      try {
       let res = await this.$Api(queryMsgList, req);
       console.log('官方++++++', res);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          list.forEach((i) => {
            i.isFollow = true;
          });
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
  },
};
</script>

<style lang="scss" scoped>
.thumbsUp {
  height: calc(100vh - 88px);
  color: #000;
  padding: 12px 16px 0;
  box-sizing: border-box;

  .listItem {
    margin-top: 18px;
    padding-bottom: 18px;
    box-sizing: border-box;
    border-bottom: 1px solid #e6e6e6;
    .topData {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .type {
        color: #333;
        font-size: 14px;
      }
      .date {
        color: #666;
        font-size: 12px;
      }
    }
    .content {
      margin-top: 12px;
      width: 100%;
      font-size: 14px;
      color: #666;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }
    .count {
      margin-top: 12px;
      font-size: 12px;
      color: #333;
    }
  }
  .listItem:first-child {
    margin-top: 0;
  }
}
</style>
